<template>
  <v-menu left bottom offset-y transition="slide-y-transition" :close-on-content-click="false">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" @click.stop="" fab x-small depressed>
            <img height="18" width="18" src="../../../assets/icons/agenda.svg" />
          </v-btn>
        </template>
        <span>Calendario - {{ today | moment("DD [de] MMMM [de] YYYY") }}</span>
      </v-tooltip>
    </template>

    <v-date-picker v-model="picker" :first-day-of-week="1" locale="es-ES" readonly no-title>
      <v-spacer />
      Hoy - {{ today | moment("DD [de] MMMM [de] YYYY") }}
      <v-spacer />
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
  }),
};
</script>

<style lang="scss" scoped></style>
